/*!
 * bootstrap-fileinput v5.0.8
 * http://plugins.krajee.com/file-input
 *
 * Font Awesome icon theme configuration for bootstrap-fileinput. Requires font awesome assets to be loaded.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2019, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
(function ($) {
  "use strict";
  const footerTemplate = '<div class="file-thumbnail-footer" style ="height:94px">\n' +
    '  <input class="kv-input kv-new form-control input-sm form-control-sm text-center {TAG_CSS_NEW}" value="{caption}" placeholder="Enter caption...">\n' +
    '  <input class="kv-input kv-init form-control input-sm form-control-sm text-center {TAG_CSS_INIT}" value="{TAG_VALUE}" placeholder="Enter caption...">\n' +
    '   <div class="small" style="margin:15px 0 2px 0">{size}</div> {progress}\n{actions}\n' +
    '</div>';

  $.fn.fileinputThemes.la = {
    fileActionSettings: {
      removeIcon: '<i class="la la-trash"></i>',
      uploadIcon: '<i class="la la-upload"></i>',
      uploadRetryIcon: '<i class="la la-repeat"></i>',
      downloadIcon: '<i class="la la-download"></i>',
      zoomIcon: '<i class="la la-search-plus"></i>',
      dragIcon: '<i class="la la-arrows"></i>',
      indicatorNew: '<i class="la la-plus-circle text-warning"></i>',
      indicatorSuccess: '<i class="la la-check-circle text-success"></i>',
      indicatorError: '<i class="la la-exclamation-circle text-danger"></i>',
      indicatorLoading: '<i class="la la-hourglass text-muted"></i>',
      indicatorPaused: '<i class="la la-pause text-info"></i>',
      showDrag: false
    },
    layoutTemplates: {
      fileIcon: '<i class="la la-file kv-caption-icon"></i> ',
      actions: '<div class="file-actions">\n' +
        '    <div class="file-footer-buttons">\n' +
        '        {upload} {download} {delete} {zoom} {other}' +
        '    </div>\n' +
        '    {drag}\n' +
        '    <div class="clearfix"></div>\n' +
        '</div>',
    },
    previewZoomButtonIcons: {
      prev: '<i class="la la-caret-left la-lg"></i>',
      next: '<i class="la la-caret-right la-lg"></i>',
      toggleheader: '<i class="la la-fw la-arrows-v"></i>',
      fullscreen: '<i class="la la-fw la-arrows-alt"></i>',
      borderless: '<i class="la la-fw la-external-link"></i>',
      close: '<i class="la la-fw la-remove"></i>'
    },
    previewFileIcon: '<i class="la la-file"></i>',
    browseIcon: '<i class="la la-folder-open"></i>',
    removeIcon: '<i class="la la-trash"></i>',
    cancelIcon: '<i class="la la-ban"></i>',
    pauseIcon: '<i class="la la-pause"></i>',
    uploadIcon: '<i class="la la-upload"></i>',
    msgValidationErrorIcon: '<i class="la la-exclamation-circle"></i> '
  };
})(window.jQuery);

var imageFactoryRaktaroid = function (obj, callback) {
    var that = this;
    var img = this.imagePrototype.cloneNode(false);
    var url = obj;
    var backgroundSize = this.options.stretchImages;
    var called;
    var element;
    var title;
    function callbackWrapper (event) {
        if (!called) {
            event = {
                type: event.type,
                target: element
            };

            if (!element.parentNode) {
                // Fix for IE7 firing the load event for
                // cached images before the element could
                // be added to the DOM:
                return that.setTimeout(callbackWrapper, [event])
            };
            called = true;
            $(img).off('load error', callbackWrapper);

            if (backgroundSize) {
                if (event.type === 'load') {
                    element.style.background = 'url("' + url +
                        '") center no-repeat';
                    element.style.backgroundSize = backgroundSize
                }
            }
            callback(event)
        }
    }
    if (typeof url !== 'string') {
        url = this.getItemProperty(obj, this.options.urlProperty);
        title = this.getItemProperty(obj, this.options.titleProperty)
    }
    if (backgroundSize === true) {
        backgroundSize = 'contain'
    }
    backgroundSize = this.support.backgroundSize &&
        this.support.backgroundSize[backgroundSize] && backgroundSize;
    if (backgroundSize) {
        element = this.elementPrototype.cloneNode(false);
    } else {
        element = img;
        img.draggable = false;
    }
    if (title) {
        element.title = title;
    }
    $(img).on('load error', callbackWrapper);
    img.src = url;

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function(e) {
        var ori = 0;
        if (this.status == 200) {
            var blob = this.response;
            loadImage.parseMetaData(blob, function(data) {
                if (data.exif) {
                    ori = data.exif.get('Orientation');
                }
                loadImage(
                    blob,
                    function(imgTag) {
                        // element = imgTag;
                        $(img).replaceWith(imgTag);
                    }, {
                        canvas: true,
                        orientation: ori
                    }
                );
            });
        }
    };
    xhr.send();

    return element
};

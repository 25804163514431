// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

$(document).ready(function() {
    $('#location-selector li ul').toggle();
    $('li > ul').each(function(i) {
        const parent_li = $(this).parent('li');
        parent_li.addClass('folder');
    });
    $('#location-selector label').on('click', function() {
        $(this).parent().find('ul').toggle();
    });

    $('#location-selector li input').on('click', function(e) {
        const $this = $(this);
        const siblingUl = $this.siblings('ul');
        const childCheckboxes = $this.siblings('ul').find('input');
        let childCheckboxesCount = childCheckboxes.length;

        if ($this.is(":checked")) {
            // checked=true to parent
            $this.parents('.folder').find('input:first').prop('checked', true);
            // set checked=true to children checkboxes
            childCheckboxes.prop('checked', true);
            if (childCheckboxesCount === 0) {
                toastr.info('A helyszín kiválasztva.')
            } else {
                toastr.info('A helyszín és az összes alhelyszíne kiválasztva.')
            }
        } else {
            const parentLi = $this.parent('li');
            let checkedChildrenCount = siblingUl.find('input:checked').length;

            // if all children are checked, uncheck, and keep clicked parent checked
            if  (parentLi.hasClass('folder') && checkedChildrenCount === childCheckboxesCount){
                childCheckboxes.prop('checked', false);
                e.preventDefault();
                toastr.info('Alhelyszínek nincsenek kiválasztva.')
            }
            // if one of the children checked, check all, and keep clicked parent checked
            else if (parentLi.hasClass('folder') && checkedChildrenCount > 0 && checkedChildrenCount != childCheckboxesCount) {
                childCheckboxes.prop('checked', true);
                e.preventDefault();
                toastr.info('Összes alhelyszín kiválasztva.')
            }
            // if all children checked, remove checked, and uncheck clicked parent
            else {
                parentLi.find('input').prop('checked', false);
                toastr.info('Helyszín és alhelyszínei nincsenek kiválasztva.')
            }
        }
    });
});
